import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import small1 from "../images/small-1.jpg"
import small2 from "../images/small-2.jpg"
import Hero from "../components/hero";
import SocialMedia from "../components/social-media";
import {Helmet} from "react-helmet";
import IconBlack from "../images/icon-black.png";
import image from "../images/drills/image1.jpg"


function LargeGroupTraining(){
    return(
        <div>
            <Helmet>
                <title>Kinetic Impact | Daily Drills</title>
            </Helmet>
            <Nav/>
            <div className="lg:pt-12 pb-12 bg-gray-100">
                {/* Code block starts */}

                <div className="w-full px-6">
                    <div className="mt-8 relative rounded-lg bg-gradient-to-r from-accent to-[#20a264] container mx-auto flex flex-col items-center pt-12 sm:pt-24 pb-24 sm:pb-32 md:pb-48 lg:pb-56 xl:pb-64">
                        <img className="mr-2 lg:mr-12 mt-2 lg:mt-12 absolute right-0 top-0" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg2.svg" alt="bg" />
                        <img className="ml-2 lg:ml-12 mb-2 lg:mb-12 absolute bottom-0 left-0" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg3.svg" alt="bg" />
                        <div className="w-11/12 sm:w-2/3 mb-5 sm:mb-10">
                            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-white font-bold leading-tight">Daily Drills</h1>
                        </div>
                        <img className={"w-28 pb-10"} src={IconBlack} alt={"icon"}/>
                    </div>
                    <div className="container mx-auto flex justify-center md:-mt-56 -mt-20 sm:-mt-40">
                        <div className="relative sm:w-2/3 w-11/12">
                            <div id={"content-start"} className={"flex flex-col justify center max-w-5xl mx-auto px-8 pt-8 text-lg bg-white rounded-xl"}>
                                <p>Below are your daily drills (click to watch). Hit the form just like you were coached to get the most out of each rep. Every rep is an opportunity to become a more efficient athlete. </p>
                                <div className={"py-8 max-w-xl mx-auto"}>
                                <table className={"table-fixed"}>
                                    <tbody>
                                    <tr className={"border-b-2 dark:bg-gray-800 dark:border-gray-700"}>
                                        <td className={"py-3 px-6"}><a className={"text-cyan-500 hover:text-cyan-600"} href="https://youtube.com/shorts/d_jmlcKN-Y4" target="_blank" rel="noopener">Low Bear Hold</a></td>
                                        <td className={"py-3 px-6"}>1x20s</td>
                                    </tr>
                                    <tr className={"border-b-2 dark:bg-gray-800 dark:border-gray-700"}>
                                        <td className={"py-3 px-6"}><a className={"text-cyan-500 hover:text-cyan-600"} href="https://youtube.com/shorts/1oQnh_F_rPo" target="_blank" rel="noopener">Low Bear March</a></td>
                                        <td className={"py-3 px-6"}>1x10</td>
                                    </tr>
                                    <tr className={"border-b-2 dark:bg-gray-800 dark:border-gray-700"}>
                                        <td className={"py-3 px-6"}><a className={"text-cyan-500 hover:text-cyan-600"} href="https://youtu.be/aka-XP2m3L4" target="_blank" rel="noopener">Glute Bridge Calf Raise</a></td>
                                        <td className={"py-3 px-6"}>1x20</td>
                                    </tr>
                                    <tr>
                                        <td className={"py-3 px-6"}><a className={"text-cyan-500 hover:text-cyan-600"} href="https://youtu.be/QSJKFkOu59g" target="_blank" rel="noopener">Prying Squat</a></td>
                                        <td className={"py-3 px-6"}>1x10</td>
                                    </tr>
                                    </tbody>
                                </table>
                                </div>
                                <div className={"py-8 max-w-xl mx-auto"}>
                                    <table className={"table-fixed"}>
                                        <tbody>
                                        <tr className={"border-b-2 dark:bg-gray-800 dark:border-gray-700"}>
                                            <td className={"py-3 px-6"}><a className={"text-cyan-500 hover:text-cyan-600"} href="https://youtu.be/89glCFg99eU" target="_blank" rel="noopener">2 Leg Snap downs with Ball</a></td>
                                            <td className={"py-3 px-6"}>2x5</td>
                                        </tr>
                                        <tr className={"border-b-2 dark:bg-gray-800 dark:border-gray-700"}>
                                            <td className={"py-3 px-6"}><a className={"text-cyan-500 hover:text-cyan-600"} href="https://youtu.be/89glCFg99eU" target="_blank" rel="noopener">Side Snap Down with Ball</a></td>
                                            <td className={"py-3 px-6"}>2x5</td>
                                        </tr>
                                        <tr className={"border-b-2 dark:bg-gray-800 dark:border-gray-700"}>
                                            <td className={"py-3 px-6"}><a className={"text-cyan-500 hover:text-cyan-600"} href="https://youtu.be/z9PfWmMES1s" target="_blank" rel="noopener">Ball Fake Overhead Throw</a></td>
                                            <td className={"py-3 px-6"}>2x5</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <p className={"py-4"}>Learn what it takes for athletes to go from good to great. We offer group classes twice a week that will teach your athlete how to become stronger, faster and jump higher as a bi-product of smart strength and conditioning training that keeps them SAFE. </p>

                                <p className={"py-4"}><span className={"font-bold"}>When:</span> Tuesday and Thursday Evenings</p>
                                {/*<iframe className={"aspect-video py-4"} src="https://www.youtube.com/embed/xycjt12igYg"*/}
                                {/*        title="YouTube video player" frameBorder="0"*/}
                                {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                                {/*        allowFullScreen></iframe>*/}
                                <a className={"pt-4 pb-4 text-cyan-500 hover:text-cyan-60"} href={"https://www.youtube.com/watch?v=xycjt12igYg"}>https://www.youtube.com/watch?v=xycjt12igYg</a>
                                <p className={"pt-4"}>Contact us to join the team!</p>
                                <div className={"lg:flex lg:flex-row lg:justify-center "}>
                                    <a className={" pb-10 text-gray-700"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Contact Us! </button></a>
                                </div>
                                <p className={"py-4"}>Numbers don’t lie. After 8 Weeks of training, here are some results from a recent group:</p>
                                <img src={image} alt={"performance image"}/>
                                <p className={"pt-4"}>Contact us to join the team!</p>
                                <div className={"lg:flex lg:flex-row lg:justify-center pb-5"}>
                                    <a className={" pb-10 text-gray-700"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Contact Us! </button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Code block ends */}
            </div>


            <Footer/>
        </div>
    )
}

export default LargeGroupTraining